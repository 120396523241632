import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/POSReport/POSReportColumns';
import { Button, Accordion } from 'react-bootstrap';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Badge } from "react-bootstrap";
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import Formatting from '../Utilities/format';
import COMMON_API from '../../Api/CommonApi';
import MASTER_API from '../../Api/MasterApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import moment from 'moment';
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap"
import ERROR_MESSAGE from '../Validation/ErrorMessage';
const ExcelJS = require('exceljs');


export const POSReport = () => {
    const user = getLoggedInUser();
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    const [billingList, setBillingList] = useState([]);
    const [billingListCount, setBillingListCount] = useState(0);
    const [billingDiscount, setDiscount] = useState(0);
    const [billingBuyBackAmount, setBuyBackAmount] = useState(0);
    const [billingAmount, setBillingAmount] = useState(0);
    const columns = useMemo(() => COLUMNS, [])
    const data = billingList
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const { pageIndex } = state

    const statusOptions = [
        { status_code: COMMONVARIABLES.DROPDOWN_VALUE.ACTIVE, status_name: COMMONVARIABLES.DROPDOWN_LABEL.ACTIVE },
        { status_code: COMMONVARIABLES.DROPDOWN_VALUE.CANCELLED, status_name: COMMONVARIABLES.DROPDOWN_LABEL.CANCELLED },
    ];
    const [branchOptions, setBranchOptions] = useState([])
    const [voucherTypeOptionsFiltered, setVoucherTypeOptionsFiltered] = useState([]);
    const [voucherTypeOptions, setVoucherTypeOptions] = useState([])
    const [customerOptions, setCustomerOptions] = useState([])

    const [billNo, setBillNo] = useState(localStorage.getItem('posReportBillNo') || "")
    const [mobileNo, setMobileNo] = useState(localStorage.getItem('posReportMobileNo') || "")
    const [status, setStatus] = useState(JSON.parse(localStorage.getItem('posReportStatus')) || "")
    const [branch, setBranch] = useState(JSON.parse(localStorage.getItem('posReportBranchName')) || "")
    const [voucherType, setVoucherType] = useState(JSON.parse(localStorage.getItem('posReportVoucherType')) || "")
    const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem('posReportCustomerName')) || "")
    const [range, setRange] = useState(localStorage.getItem('posReportRange') || null)

    // console.log(voucherType);
    // console.log(range);
    // console.log(rangeDate);

    var start_date = localStorage.getItem('posReportRange') && new Date(localStorage.getItem('posReportRange').split('-')[0]).toDateString();
    var end_date = localStorage.getItem('posReportRange') && new Date(localStorage.getItem('posReportRange').split('-')[1]).toDateString();

    const [rangeDate, setRangeDate] = useState(localStorage.getItem('posReportRange') && localStorage.getItem('posReportRange') !== "null" ? { startDate: new Date(start_date), endDate: new Date(end_date) } : { startDate: new Date(), endDate: new Date() })

    // console.log(rangeDate);

    var estimate_status = false;
    if (localStorage.getItem('itemWiseSalesReportEstimate') === "true" || localStorage.getItem('posReportEstimate') === true) {
        estimate_status = true;
    } else if (localStorage.getItem('itemWiseSalesReportEstimate') === "false" || localStorage.getItem('posReportEstimate') === false) {
        estimate_status = false;
    }

    const [estimate, setEstimate] = useState(estimate_status || false)

    const [isLoading, setIsLoading] = useState(false);

    const udfnPhoneNumberValidation = (event) => {
        const regex = /^[0-9]*$/;
        const input = event.target.value + event.key;
        if (!regex.test(input) || input.length > 10) {
            event.preventDefault();
        }
    };

    const udfnBillingData = async () => {
        setIsLoading(true)
        try {
            const customerParams = { user_id: Number(user?.id), status_code: Number(0) };
            const branchParams = { user_id: Number(user?.id), status_code: Number(0) };
            const voucherTypeParams = {
                user_id: Number(user?.id),
                status_code: Number(0),
                branch_code: branch?.value === '' || branch?.value === undefined ? 0 : Number(branch?.value)
            };

            const [customerData, branchData, voucherTypeData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.CUSTOMER_MASTER_LIST, customerParams, config),
                axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),
                axios.post(BASE_URL + COMMON_API.VOUCHER_TYPE_LIST, voucherTypeParams, config),
            ]);

            setIsLoading(false)
            if (customerData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || voucherTypeData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || branchData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                var errorMsg = customerData?.data?.body?.message || branchData?.data?.body?.message || voucherTypeData?.data?.body?.message;
                toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (customerData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || voucherTypeData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnBillingData();
                    }
                }
            }

            if (customerData?.data?.body?.customerDetails || branchData?.data?.body?.branchList || voucherTypeData?.data?.body?.voucherTypeList) {
                setCustomerOptions(customerData?.data?.body?.customerDetails)
                setBranchOptions(branchData?.data?.body?.branchList)
                setVoucherTypeOptions(voucherTypeData?.data?.body?.voucherTypeList)
                setVoucherTypeOptionsFiltered(voucherTypeData?.data?.body?.voucherTypeList)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    async function udfnBillingList() {
        try {
            setIsLoading(true)
            setBillingList([])
            const user = getLoggedInUser()
            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const params = {
                user_id: Number(user?.id),
                from_date: varFromDate,
                to_date: varToDate,
                customer_code: customer?.value === '' || customer?.value === undefined ? 0 : customer?.value,
                branch_code: branch?.value === '' || branch?.value === undefined ? 0 : branch?.value,
                voucher_type_code: voucherType?.value === '' || voucherType?.value === undefined ? 0 : voucherType?.value,
                status_code: status?.value === '' || status?.value === undefined ? 0 : status?.value,
                bill_no: billNo === '' || billNo === null ? String(0) : String(billNo),
                mobile_no: mobileNo === '' || mobileNo === null ? String('0') : mobileNo,
                bill_type: estimate ? 0 : 1
            };
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [billingList] = await Promise.all([
                axios.post(BASE_URL + MASTER_API.BILLING_LIST, params, config),

            ]);
            setIsLoading(false)

            if (billingList && billingList?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(billingList?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (billingList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnBillingList();
                    }
                }
            } else if (billingList?.data?.body?.billDetails) {
                setBillingList(billingList?.data?.body?.billDetails)
                var total = 0, totaldiscount = 0, totalBuyBack = 0, totalbillingAmount = 0;
                billingList?.data?.body?.billDetails.forEach(row => {
                    total = total + row.ps_total_amount;
                    totaldiscount = totaldiscount + row.ps_discount;
                    totalBuyBack = totalBuyBack + row.ps_buy_back_amount;
                    totalbillingAmount = totalbillingAmount + row.ps_amount;
                });
                // total = 76583246.68785;
                console.log("Buyback", totalBuyBack);
                setBillingListCount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
                setDiscount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', totaldiscount)))
                setBuyBackAmount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', totalBuyBack)))
                setBillingAmount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', totalbillingAmount)))
            }

        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    const udfnEstimateValue = (value) => {
        var returnVal = value === 'true' ? false : true;
        setEstimate(returnVal);
    };

    useEffect(() => {
        var bill_type = estimate ? 0 : 1;
        if (branch?.value > 0) {
            // setVoucherType('')
            setVoucherType(JSON.parse(localStorage.getItem('posReportVoucherType')) || "")
            var voucherTypeOptionsArray = voucherTypeOptions
            var filtArray = voucherTypeOptionsArray.filter(item => item.vt_branch_code === branch?.value && item.vt_stock_applicable === bill_type)
            setVoucherTypeOptionsFiltered(filtArray)
        } else {
            var voucherTypeOptionsArray = voucherTypeOptions
            var filtArray = voucherTypeOptionsArray.filter(item => item.vt_stock_applicable === bill_type)
            setVoucherTypeOptionsFiltered(filtArray)
        }
    }, [branch?.value, estimate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setBillingList([])
    }, [range, billNo, status?.value, branch?.value, voucherType?.value, customer?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        udfnBillingData()
        udfnBillingList()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const exportToExcel = async () => {
        setIsLoading(true)
        const arraydata = billingList

        var listCount = billingList.length

        if (listCount > 0) {

            // colorWiseTotalDispatch
            let POS_Report = []

            let total_cash = 0
            let total_card = 0
            let total_online = 0
            let amount = 0
            let total_tax_amount = 0
            let total_discount_amount = 0
            let total_amount = 0

            POS_Report = arraydata.map((e, index) => {
                total_cash = total_cash + e.ps_cash
                total_card = total_card + e.ps_card
                total_online = total_online + e.ps_online
                amount = amount + e.ps_amount
                total_tax_amount = total_tax_amount + e.ps_tax_amount
                total_discount_amount = total_discount_amount + e.ps_discount
                total_amount = total_amount + e.ps_total_amount

                return {
                    "S.No.": index + 1, "DATE": e.ps_bill_date, "BILL NO": e.ps_bill_text, "VOUCHER TYPE": e.vt_voucher_type_name, 'BRANCH NAME': e.bm_branch_name, 'CUSTOMER NAME': e.cm_customer_name, 'CASH': COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.ps_cash)), "CARD": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.ps_card)), "ONLINE": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.ps_online)), "AMOUNT": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.ps_amount)), "Tax Amount": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.ps_tax_amount)), "DISCOUNT AMOUNT": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.ps_discount)), "TOTAL AMOUNT": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.ps_total_amount)), "STATUS": e.status
                }
            })

            const data_array = []
            POS_Report.forEach((row) => {
                data_array.push(Object.values(row))
            })

            var array_data_count = data_array.length;

            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const title = 'POS Report On '
            const frontside = '('
            const backside = ')'
            const tofrontside = '  to ('
            const reportData = {
                title: title + frontside + moment(new Date(varFromDate)).format("DD-MM-YYYY ") + backside + tofrontside + moment(new Date(varToDate)).format("DD-MM-YYYY") + backside,
                data: data_array,
                headers: Object.keys(POS_Report[0])
            }

            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('pos-report')


            worksheet.mergeCells('A1', 'N1')
            const titleRow = worksheet.getCell('A1')
            titleRow.value = reportData['title']
            titleRow.font = {
                name: 'Calibri',
                size: 16,
                bold: true,
                color: { argb: '000000' }
            }
            titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

            worksheet.mergeCells('A2', 'G2')
            const customer_head = 'Curomer Name : '
            const customer_val = customer.label ? customer.label : 'All'
            const customerRow = worksheet.getCell('A2')
            customerRow.value = customer_head + customer_val
            customerRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            customerRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('H2', 'N2')
            const branch_head = 'Branch Name : '
            const branch_val = branch.label ? branch.label : 'All'
            const branchRow = worksheet.getCell('H2')
            branchRow.value = branch_head + branch_val
            branchRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            branchRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('A3', 'G3')
            const status_head = 'Status : '
            const status_val = status.label ? status.label : 'All'
            const statusRow = worksheet.getCell('A3')
            statusRow.value = status_head + status_val
            statusRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            statusRow.alignment = { vertical: 'left', horizontal: 'left' }


            worksheet.mergeCells('H3', 'N3')
            const estimate_head = 'Type : '
            const estimate_val = estimate === true ? 'Estimate' : 'Bill'
            const estimateRow = worksheet.getCell('H3')
            estimateRow.value = estimate_head + estimate_val
            estimateRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            estimateRow.alignment = { vertical: 'left', horizontal: 'left' }


            worksheet.mergeCells('A4', 'G4')
            const voucher_type_head = 'Voucher Type : '
            const voucher_type_val = voucherType.label ? voucherType.label : 'All'
            const voucherTypeRow = worksheet.getCell('A4')
            voucherTypeRow.value = voucher_type_head + voucher_type_val
            voucherTypeRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            voucherTypeRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('H4', 'N4')
            const bill_head = 'Bill No : '
            const bill_val = billNo ? billNo : '-'
            const billNoRow = worksheet.getCell('H4')
            billNoRow.value = bill_head + bill_val
            billNoRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            billNoRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('A5', 'N5')
            const mobile_no_head = 'Mobile No : '
            const mobile_no_val = mobileNo ? mobileNo : '-'
            const mobileNoRow = worksheet.getCell('A5')
            mobileNoRow.value = mobile_no_head + mobile_no_val
            mobileNoRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            mobileNoRow.alignment = { vertical: 'left', horizontal: 'left' }

            const headerRow = worksheet.addRow(reportData['headers'])
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ff8b3d' },
                    bgColor: { argb: 'ff8b3d' }
                }
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' },
                    size: 12
                }
            })

            data_array.forEach(d => {
                worksheet.addRow(d)
            })

            var format_total_cash = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_cash))
            var format_total_card = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_card))
            var format_total_online = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_online))
            var format_total_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_amount))
            var format_total_tax_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_tax_amount))
            var format_total_discount_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_discount_amount))
            var format_total_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_amount))

            const total = ['', '', '', '', '', 'Total', format_total_cash, format_total_card, format_total_online, format_total_amount, format_total_tax_amount, format_total_discount_amount, format_total_amount]
            const footerRow = worksheet.addRow(total)
            footerRow.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    color: { argb: '000000' },
                    size: 14
                }
            })
            footerRow.alignment = { vertical: 'middle', horizontal: 'right' }

            var starting_cell = 7;
            var last_cell = array_data_count + starting_cell

            for (let i = starting_cell; i <= last_cell; i++) {
                const cashColumn = worksheet.getCell('G' + i)
                const cardColumn = worksheet.getCell('H' + i)
                const onlineColumn = worksheet.getCell('I' + i)
                const amountColumn = worksheet.getCell('J' + i)
                const taxAmountColumn = worksheet.getCell('K' + i)
                const discountAmountColumn = worksheet.getCell('L' + i)
                const totalAmountColumn = worksheet.getCell('M' + i)
                cashColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                cardColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                onlineColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                amountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                taxAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                discountAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                totalAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
            }

            worksheet.getColumn(1).width = 5
            worksheet.getColumn(2).width = 20
            worksheet.getColumn(3).width = 20
            worksheet.getColumn(4).width = 20
            worksheet.getColumn(5).width = 20
            worksheet.getColumn(6).width = 25
            worksheet.getColumn(7).width = 15
            worksheet.getColumn(8).width = 15
            worksheet.getColumn(9).width = 15
            worksheet.getColumn(10).width = 15
            worksheet.getColumn(11).width = 15
            worksheet.getColumn(12).width = 23
            worksheet.getColumn(13).width = 20
            worksheet.getColumn(14).width = 15

            // Save the workbook
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `POS Report.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } else {
            toast.error(ERROR_MESSAGE.EXPORT_TO_EXCEL_NO_RECORD, { position: toast.POSITION.TOP_RIGHT });
        }
        setIsLoading(false)
    }

    useEffect(() => {
        localStorage.setItem('posReportRange', range)
        localStorage.setItem('posReportCustomerName', JSON.stringify(customer))
        localStorage.setItem('posReportStatus', JSON.stringify(status))
        localStorage.setItem('posReportEstimate', estimate)
        localStorage.setItem('posReportBranchName', JSON.stringify(branch))
        localStorage.setItem('posReportVoucherType', JSON.stringify(voucherType))
        localStorage.setItem('posReportBillNo', billNo)
        localStorage.setItem('posReportMobileNo', mobileNo)
    }, [range, customer, status, estimate, branch, voucherType, billNo, mobileNo])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ToastContainer />
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <div className="card">
                    <div className="card-header pt-3 pb-0">
                        <div className='col-sm-2'>
                            <h4 className="card-title">POS Report</h4>
                        </div>
                        <div className='col-sm-2 mb-2'>
                            <div className="bg-secondary coin-holding flex-wrap py-0 m-0">
                                <div className="mb-2 coin-bx">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <i className="fa fa-rupee udfn-rupee-icon"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="coin-font font-w600 mb-0 text-white mt-2">Sub Total</h4>
                                            <p className="mb-0 text-white op-6"> {billingAmount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2 mb-2'>
                            <div className="bg-primary coin-holding flex-wrap py-0 m-0">
                                <div className="mb-2 coin-bx">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <i className="fa fa-rupee udfn-rupee-icon"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="coin-font font-w600 mb-0 text-white mt-2">Discount</h4>
                                            <p className="mb-0 text-white op-6"> {billingDiscount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2 mb-2'>
                            <div className="bg-dark coin-holding flex-wrap py-0 m-0">
                                <div className="mb-2 coin-bx">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <i className="fa fa-rupee udfn-rupee-icon"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="coin-font font-w600 mb-0 text-white mt-2">Buy Back</h4>
                                            <p className="mb-0 text-white op-6"> {billingBuyBackAmount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2 mb-2'>
                            <div className="bg-success coin-holding flex-wrap py-0 m-0">
                                <div className="mb-2 coin-bx">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <i className="fa fa-rupee udfn-rupee-icon"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="coin-font font-w600 mb-0 text-white mt-2">Total</h4>
                                            <p className="mb-0 text-white op-6"> {billingListCount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='cash-total col-sm-1 text-center'>
                            <Button variant="info" className="mb-1" onClick={() => exportToExcel()}>
                                <i className="fa fa-download" /> &nbsp; Export
                            </Button>
                        </div>
                    </div>
                    
                    <div className="card-body">
                        <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header className="accordion-header accordion-header-primary">
                                    <h5>Filters</h5>
                                    <span className="accordion-header-indicator "></span>
                                </Accordion.Header>
                                <Accordion.Collapse className="accordion__body">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-3 mb-3">
                                                <div className="example rangeDatePicker">
                                                    <p className="mb-1">Select From Date And To Date</p>
                                                    <DateRangePicker
                                                        onApply={(e) => {
                                                            setRange(e.target.value)
                                                        }}
                                                        initialSettings={rangeDate}
                                                    >
                                                        <input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Customer Name</p>
                                                <Select
                                                    defaultValue={customer}
                                                    onChange={setCustomer}
                                                    options={customerOptions.map((option) => ({
                                                        label: option?.cm_customer_name,
                                                        value: option?.cm_customer_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Branch Name</p>
                                                <Select
                                                    defaultValue={branch}
                                                    onChange={setBranch}
                                                    options={branchOptions.map((option) => ({
                                                        label: option?.bm_branch_name,
                                                        value: option?.bm_branch_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Status</p>
                                                <Select
                                                    defaultValue={status}
                                                    onChange={setStatus}
                                                    options={statusOptions.map((option) => ({
                                                        label: option?.status_name,
                                                        value: option?.status_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <p className="text-center mb-1">Estimate </p>
                                                <p className='text-center'>
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input p-2"
                                                        value={estimate}
                                                        onChange={(e) => udfnEstimateValue(e.target.value)}
                                                        checked={estimate === true || estimate === "true"}
                                                    />
                                                </p>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Voucher Type</p>
                                                <Select
                                                    defaultValue={voucherType}
                                                    onChange={setVoucherType}
                                                    options={voucherTypeOptionsFiltered.map((option) => ({
                                                        label: option?.voucher_type_name,
                                                        value: option?.voucher_type_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Bill No.</p>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={billNo}
                                                    onChange={(e) => setBillNo(e.target.value)}
                                                    placeholder="Bill No."
                                                    maxLength="50"
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <p className="mb-1">Mobile No.</p>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={mobileNo}
                                                    onChange={(e) => setMobileNo(e.target.value)}
                                                    onKeyPress={(e) => udfnPhoneNumberValidation(e)}
                                                    placeholder="Mobile No."
                                                />
                                            </div>
                                            <div className="col-md-2 mt-3">
                                                <Button className="me-2" variant="whatsapp" style={{ marginTop: "6%" }} onClick={() => { udfnBillingList() }}>
                                                    List{" "}
                                                    <span className="btn-icon-right">
                                                        <i className="fa fa-filter" />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        <div className="table-responsive">
                            <div className='row'>
                                <div className='col-sm-9'>
                                    {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                                </div>
                            </div>
                            <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                <thead>
                                    {headerGroups.map((headerGroup, index) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                            {/* <th>
                                                <p className='tbl-heading-txt'>S.NO.</p>
                                            </th> */}
                                            {headerGroup.headers.map((column, index) => (
                                                <>
                                                    {column.Header === 'Amount' || column.Header === 'Tax Amount' || column.Header === 'Discount' || column.Header === 'Cash' || column.Header === 'Card' || column.Header === 'Online' || column.Header === 'Total Amount' ?
                                                        <th className='text-end' key={index}>
                                                            {column.render('Header')}
                                                        </th>
                                                        :
                                                        <th key={index}>
                                                            {column.render('Header')}
                                                        </th>
                                                    }
                                                </>
                                            ))}
                                            {/* <th>
                                                <p className='tbl-heading-txt'>Actions</p>
                                            </th> */}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} className="" >
                                    {page.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td width='2%'>{index + 1}</td>
                                                <td><div style={{ width: '90px' }}>{row.original.ps_bill_date}</div></td>
                                                <td>

                                                    <td width="7%"><div>{row.original.ps_bill_text}</div>{row.original.bill_type == "Estimate" ? <div className='badge-primary light'>{row.original.bill_type}</div> : null}</td>
                                                    {/* <div style={{ width: '60px' }}>{row.original.ps_bill_text}</div> */}
                                                </td>
                                                <td>{row.original.vt_voucher_type_name}</td>
                                                <td>{row.original.bm_branch_name}</td>
                                                <td>{row.original.cm_customer_name}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.ps_cash))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.ps_card))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.ps_online))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.ps_amount))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.ps_tax_amount))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.ps_discount))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.ps_buy_back_amount))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.ps_total_amount))}</td>
                                                <td width="5%">
                                                    {row.original.status === "Active" ? <Badge bg="" className='badge-success light'>
                                                        <span className="me-2">
                                                            <i className="ms-1 fa fa-check" />
                                                        </span>
                                                        {row.original.status}
                                                    </Badge> : <Badge bg="" className='badge-primary light'>
                                                        <span className="me-2">
                                                            <i className="fa fa-times"></i>
                                                        </span>
                                                        {row.original.status}
                                                    </Badge>
                                                    }
                                                </td>
                                                <td width="5%">
                                                   
                                                {row.original.invoice_url != "" && row.original.invoice_url != null ?
                                                        <Link

                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            const newWindow = window.open(`https://my.gstzen.in${row.original.invoice_url}`, '_blank');
                                                            if (newWindow) {
                                                              newWindow.opener = null;  // Security measure to prevent access to the parent window
                                                            }
                                                        }}
                                                        target="_blank"
                                                        //    to={`${row.original.invoice_url}`}
                                                            className="badge-primary light	 shadow btn-xs sharp me-0" variant="primary">
                                                            <i className="fa fa-file" id="converttooltip"></i>

                                                            <UncontrolledTooltip placement="top" target="converttooltip">
                                                           {row.original.invoice_url}
                                                            </UncontrolledTooltip>

                                                        </Link>
                                                        : null}

                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='text-center'>
                                {billingList.length < 1 ? "No records found." : ""}
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{''}
                                </span>
                                <span className="table-index">
                                    Go to page : {' '}
                                    <input type="number"
                                        className="ms-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="filter-pagination  mt-3">
                                    <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                    <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}
export default POSReport;