import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	{
		Header: 'S.No.',
		Footer: 'S.No.',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Date',
		Footer: 'Date',
		accessor: 'ps_bill_date',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Bill No.',
		Footer: 'Bill No.',
		accessor: 'ps_bill_text',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Voucher Type',
		Footer: 'Voucher Type',
		accessor: 'vt_voucher_type_name',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Branch Name',
		Footer: 'Branch Name',
		accessor: 'bm_branch_name',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Customer Name',
		Footer: 'Customer Name',
		accessor: 'cm_customer_name',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Cash',
		Footer: 'Cash',
		accessor: 'ps_cash',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Card',
		Footer: 'Card',
		accessor: 'ps_card',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Online',
		Footer: 'Online',
		accessor: 'ps_online',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Amount',
		Footer: 'Amount',
		accessor: 'ps_amount',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Tax Amount',
		Footer: 'Tax Amount',
		accessor: 'ps_tax_amount',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Disc Amount',
		Footer: 'Disc Amount',
		accessor: 'ps_discount',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Buy Back',
		Footer: 'Buy Back',
		accessor: 'ps_buy_back_amount',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Total Amount',
		Footer: 'Total Amount',
		accessor: 'ps_total_amount',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'E-Invoice',
		Footer: 'E-Invoice',
		accessor: 'invoice_url',
		Filter: ColumnFilter,
		disableFilters: true,
	}
]

export const GROUPED_COLUMNS = [
]